import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import ContentPage from "../../../layouts/contentPage"

import Seo from "../../../components/seo"

import ProductHero from "../../../components/Product/productHero"
import H1 from "../../../components/h1"
import ButtonColor from "../../../components/buttonColor"
import ProductInfo from "../../../components/Product/productInfo"
import ProductPackshot from "../../../components/Product/productPackshot"
import ProductPackshotBubbles from "../../../components/Product/productPackshotBubbles"
import BubbleBg from "../../../components/Product/bubbleBg"
import ProductSidebar from "../../../components/Product/productSidebar"
import More from "../../../components/more"
import Tabs from "../../../components/Tabs/tabs"
import ProductDescription from "../../../components/Product/productDescription"
import Acid from "../../../images/media/products/acid/acid.svg";
import Ingredients from "../../../components/Product/ingredients"
import Ingredient from "../../../components/Product/ingredient"
import Effectivness from "../../../components/Product/effectivness"
import EffectivnessCard from "../../../components/Product/effectivnessCard"
import ActiveIngredients from "../../../components/Product/activeIngredients"
import ActiveIngredient from "../../../components/Product/activeIngredient"
import DescriptionPackshot from "../../../components/Product/descriptionPackshot"
import Container from "/src/components/Product/container"






const Product = () => (
    <Layout lang={"EN"}>
      <Seo title="Renewing acid peeling  - Acid Peel" lang={"EN"} />
        <ProductHero>

            {/* Info */}
            <div data-column>
                <ProductInfo color="blue">
                    <H1>Renewing <br />acid peeling  </H1>
                    <p>Hydrates, Smoothes & Resurfaces </p>
                    <ProductPackshot mobile>
                        <StaticImage
                        src="../../images/media/products/acid/products/odnawiajacy-peeling.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Renewing acid peeling "
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                    <ul>
                        <li>Firms and plumps your skin.</li>
                        <li>Smoothes deep wrinkles.</li>
                        <li>Lightens pigementation and hydrates.</li>
                        <li>Oxygenates for overall better absoption of nutrients.</li>
                    </ul>
                    <Container>
                    <ButtonColor as="a" target="_blank" href="https://www.hebe.pl/oxygenetic-odnawiajacy-peeling-kwasowy-do-twarzy-50-ml-000000000000407506.html" color="blue">Buy in Hebe</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.zikodermo.pl/oxygenetic-acid-peel-odnawiajacy-peeling-kwasowy-50-ml.html" color="blue">Buy in Ziko Dermo</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://gemini.pl/oxygenetic-acid-peel-aha-pha-odnawiajacy-peeling-kwasowy-50-ml-0107782" color="blue">Buy in Gemini</ButtonColor>
                    <ButtonColor as="a" target="_blank" href="https://www.doz.pl/apteka/p176923-Oxygenetic_Acid_Peel_AHAPHA_odnawiajacy_peeling_kwasowy_50_ml" color="blue">Buy in DOZ</ButtonColor>
                    </Container>                
                    </ProductInfo>
            </div>

            {/* Packshot */}
            <div data-column>
                <ProductPackshot>
                        <StaticImage
                        src="../../images/media/products/acid/products/odnawiajacy-peeling.png"
                        loading="eager"
                        width={580}
                        quality={95}
                        placeholder="none"
                        formats={["auto", "webp", "avif"]}
                        alt="Energetyzujące serum"
                        />
                        <ProductPackshotBubbles/>
                </ProductPackshot>
                <More url="#opis-produktu" color="blue" text="more"/>
            </div>

            {/* Category Products */}
            <div data-column>
                <ProductSidebar lang={"EN"}>
                    <Link to="/en/products/anti-aging-peeling">
                        <StaticImage
                            src="../../images/media/products/acid/products/przeciwstarzeniowy-peeling.png"
                            loading="eager"
                            // width={142}
                            quality={95}
                            placeholder="blurred"
                            formats={["auto", "webp", "avif"]}
                            alt="anti-aging peeling"
                        />
                    </Link>
                </ProductSidebar>
            </div>
            {/* <BubbleBg/> */}
        </ProductHero> 
        <section id="opis-produktu">

            {/* TABS */}
            <Tabs color="blue">

                {/* OPIS */}
                <div label="Description" className="tab-list-active">
                <ProductDescription color="blue">
                    <div className="descriptionRow">
                        <div className="column">
                            <div className="text--left">

                                <p>
                                    <strong>OXYGENETIC ACID PEEL AHA + PHA Renewing acid peeling</strong>
                                </p>
                                <p>
                                    Potent formula to stimulate hyaluronic acid synthesis and provide hydration to very demanding complexion. 
                                </p>
                                <p>
                                Reduces Transepidermal Water Loss - TEWL - to lock in moisture and prevent dehydration. Promotes collagen and elastin production  to smooth wrinkles and delay ageing processes.
                                </p>
                                <p>
                                     This peel works in deep layers of the skin, inhibits the activity of sebaceous glands by regulating waxy, oily sebum from the inside, shrinks pores and expells impurities and pollutants from the skin for noticeably improved and resurfaced skin texture. 
                                </p>
                                <p>
                                     Stimulates skin metabolites for the amazing youthful glow. You may count on AHA+PHA combination to fight dullness and acne and restore an even and radiant tone to your complexion.  
                                </p>
                            </div>
                        </div>
                        <div className="column">
                            <DescriptionPackshot>
                                <StaticImage
                                src="../../images/media/products/acid/products/odnawiajacy-peeling_single.png"
                                loading="lazy"
                                width={534}
                                quality={95}
                                placeholder="none"
                                formats={["auto", "webp", "avif"]}
                                alt="Energetyzujące serum"
                                />
                            </DescriptionPackshot>
                        </div>
                        <div className="column">
                            <div className="text--right">
                                <p><strong> How to use: </strong></p>
                                <p>Apply a thin layer of this peel on your face, neck and decollette. Use as a three-week treatment.  Apply every second day in the first week, then daily. For best effect continue using regularly 1-2 times per week depending on your needs. Use with a sunscreen or a sunblocker and avoid UV  exposure. Avoid contact with eyes.</p>
                                <br/>
                                <p><strong>Volume:</strong></p>
                                <p>50ml</p>
                                <span className="acid-peel">
                                    <Acid/>
                                </span>
                            </div>
                        </div>
                    </div>        
                </ProductDescription>
                </div>

                {/* BAZA */}
                <div label="Base">
                <Ingredients>
                    <Ingredient name="Formulated with Mandelic acid" desc="AHA acid - to renew skin structure by exfoliating top layers of the skin for improved texture and tone and to fight wrinkles. Its powerful antibacterial properties team up with cell-turnover-activating abilities. Good for the skin, merciless for wrinkles.  Stimulates huaulronic acid synthesis in the skin to firm it and restore suppleness. It comforts skin disorders like acne." />
                    <Ingredient name="Blend of oxygentaing minerals" desc="Minerals are the component which binds oxygen molecules which are absorbed by the skin and stimulates metabolic processes in the skin so that more energy is released and skin cells start to function properly for healthy and radiant look." />
                    <Ingredient name="Gluconactone" desc="is a polyhydroxy acid (PHA). It is strong , but it contains skin-calming antioxidant combination to help balance things out.  Delivers anti-aging compounds to fight skin aging and protect against ultraviolet radiation.  Mineral complex is a wonderful blend oxygenetating skin cells and metabollising agent so that skin cells get the energy to resurface and refine and  to help enjoy healthy and radiant skin look and enhance glow. " />
                </Ingredients>
                </div>

                {/* SKŁADNIKI AKTYWNE */}
                <div label="Active igredients">
                <ActiveIngredients>
                    <ActiveIngredient name="Bilberry extract" desc="the perennai shrub from Nordic nature  - rich in Vitamins C, A , E comprises a slew of minerals with antioxidant and anti-inflammatory properties to soothe and calm your skin (redenss) so that you won't have to be concerned about irritations." />
                    <ActiveIngredient name="Acerola extract" desc="a boosting and brightenieng essenst that works to visibly brightern the complexion while boosting the skin's hydration level. It 's an true antioxidant bomb, firming, plumping, anti aging and massively hydrating due to high Vitamin C, A, B and PP. " />
                    <ActiveIngredient name="Panthenol" desc="Provitamin B5, which penetrates into the deeper layers of the skin, moisturizes it and soothes irritations. Additionally, it reduces transepidermal water loss (TEWL) and restores hydrolipid balance." />
                </ActiveIngredients>
                </div>

                {/* SKUTECZNOŚĆ */}
                <div label="Effectivness">
                <Effectivness>
                    <EffectivnessCard color="blue" percentage="90" desc="badanych potwierdza, że produkt zmniejsza szorstkość skóry"/>
                    <EffectivnessCard color="blue" percentage="85" desc="badanych potwierdza, że produkt zmniejsza widoczność pierwszych zmarszczek"/>
                    <EffectivnessCard color="blue" percentage="75" desc="badanych potwierdza, że produkt reguluje wydzielanie sebum i ogranicza błyszczenie skóry"/>
                </Effectivness>
                </div>
            </Tabs>
        </section>
    </Layout>
)

export default Product
